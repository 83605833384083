import React from "react"

import SEO from "src/components/seo"
import Layout from "src/components/layout"
import GradientsNew from "src/components/gradients-new"
import TopMenu from "src/components/top_menu"
import SubpageLayout from "src/components/subpage_layout"
import SubpageFirstScreen from "src/components/subpage_first_screen"
import PriceList from "src/components/prices_list"
import Footer from "src/components/footer"
import MobileMenu from "src/components/mobile_menu"

import divisions from "src/content/divisions"

import subpageStyles from "src/css/subpage.module.css"

import outside from "src/images/outside.png"
import vampire from "src/images/pages/outside/photo-1526860474862-d2c1d76aed1f.jpg"
import { connect } from "react-redux"
import rlt_marketing from "../../images/pages/marketing/marketing-elo.jpg"

const Outdoor = ({isMobile}) => (
  <Layout>
    <SEO title="Reklama zewnętrzna - Departament Reklamy" keywords={[`departament reklamy`, `elo`, `react`]} lang="pl"/>
    <GradientsNew activeGradient={2} iconBg={divisions.outdoor.icon}/>
    {isMobile.isMobile ? (
      <MobileMenu homeBack={true}/>
    ) : (
      <TopMenu homeBack={true}/>
    )}

    <SubpageLayout>
      <SubpageFirstScreen content={{
        title: "REKLAMA ZEWNĘTRZNA",
        sitemap: [
          { title: "Opis", id: "id" },
          { title: "Efekt wampira", id: "efekt-wampira" },
          { title: "Cennik", id: "cennik" },
        ],
        slogan: "Firma widocznie lepsza",
        img: outside,
        texts: {
          1: {
            title: "LITERY PRZESTRZENNE, TELEBIMY LED,KASETONY",
            desc: "Jeśli szukasz reklamy, która będzie skuteczna i widoczna z większej odległości powinieneś spróbować " +
            "czegoś co bedzie widać nawet w ciemności.",
          },
          2: {
            title: "",
            desc: "",
          },
        },
      }} />
      <div className={[subpageStyles.subpage_block, subpageStyles.white, subpageStyles.halfimg_block].join(" ")} id="efekt-wampira">
        <div className={subpageStyles.image} style={{
          backgroundImage: "url(" + vampire + ")",
          backgroundPosition: "top right",
          backgroundSize: "auto 100%",
        }} />
        <div className={subpageStyles.content}>
          <div className={subpageStyles.title}>Efekt wampira</div>
          <div className={subpageStyles.text}>
            Jak wiele razy zdarzało Ci się jadąc samochodem, spostrzec czyjąś reklamę? Czasem ją omijasz i nic
            więcej z tym nie robisz.
          </div>
          <div className={subpageStyles.subtitle}>Czy są to zmarnowane pieniądze?</div>
          <div className={subpageStyles.text}>
            Nie, ponieważ
            zobaczyłeś tą reklamę i zapamiętałeś daną firmę, tym samym jest to efekt wampira, który powoduje, że
            zapamiętujesz pewne elementy logo i później Twój mózg przy zakupie danego produktu będzie sugerować się tym
            co jest już dla niego znane, a Ty uznasz ten produkt za bezpieczniejszy. Z tego powodu warto jest być ciągle
            widocznym.
          </div>
        </div>
      </div>

      <div className={[subpageStyles.subpage_block].join(" ")} style={{
        height: "20vh",
      }}/>
      <PriceList id="cennik" content={{
        items: [
          { title: "Telebim LED", desc: "", price: "", prefix: "wycena indywiduala" },
          { title: "Logo 3D", desc: "", price: "", prefix: "wycena indywiduala" },
        ],
        suggestions: [divisions.papers],
      }}/>
      {isMobile.isMobile ? null : (
        <Footer/>
      )}
    </SubpageLayout>
  </Layout>
)

export default connect(state => ({
  isMobile: state.isMobile,
}), null)(Outdoor)
